
  import { Component } from 'vue-property-decorator'
  import { Linked } from '.'

@Component
  export default class Selector extends Linked {
    get bind () {
      const { properties, isCreated } = this
      return {
        ...properties,
        name: isCreated ? properties.name : undefined,
        appendOuterIcon: this.icon,
      }
    }
  }
